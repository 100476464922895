import React from 'react'
import { graphql, useStaticQuery, navigate } from 'gatsby'
import {
  createStyles,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete'
import slug from 'slug';

const AutoComplete = () => {

  const data = useStaticQuery(graphql`
    query {
      allProduct(sort: {fields: title, order: ASC}) {
        nodes {
          id
          title
          slug
        }
      }
      allEvent(sort: {fields: title, order: ASC}) {
        nodes {
          id
          title
          slug
        }
      }
      MAIN_BACKGROUND_COLOR: setting(environmentName: {eq: "MAIN_BACKGROUND_COLOR"}) {
        value
    }
    }
  `)
  const MAIN_BACKGROUND_COLOR = data.MAIN_BACKGROUND_COLOR.value

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: `${MAIN_BACKGROUND_COLOR}`,
      },
      '& label': {
        fontFamily: 'Open Sans',
        fontSize: '14px'
    },
      '& .MuiInput-underline:after': {
        borderBottomColor: `${MAIN_BACKGROUND_COLOR}`,
      },
      '& .MuiInputBase-input': {
        fontFamily: 'Open Sans',
        fontSize: '14px'
    },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: `${MAIN_BACKGROUND_COLOR}`,
        },
        '&:hover fieldset': {
          borderColor: `${MAIN_BACKGROUND_COLOR}`,
        },
        '&.Mui-focused fieldset': {
          borderColor: `${MAIN_BACKGROUND_COLOR}`,
        },
      },
    },
  })(TextField);

  const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        display: 'flex',
        flexWrap: 'wrap',
      },
      margin: {
        margin: theme.spacing(1),
        width: '265px'
      },
    }),
  );

  const products = data.allProduct.nodes
  const events = data.allEvent.nodes

  const classes = useStyles();



  const setProduct = (event, values) => {
    const productSlug = slug(event, { lower: true })
    navigate(`/personalizados/${productSlug}`)
  }

  const setEvent = (event, values) => {
    const productSlug = slug(event, { lower: true })
    navigate(`/festas/${productSlug}`)
  }

  return (
    <div className={classes.root}>
      <div>
        <Autocomplete
          size="small"
          freeSolo
          onChange={(e, v) => setEvent(v)}
          options={events.map(option => option.title)}
          renderInput={params => (
            <CssTextField {...params}
              label="Pesquisar festa"
              margin="normal"
              variant="outlined"
              className={classes.margin}
            />
          )}
        />
      </div>
      <div>
        <Autocomplete
          size="small"
          freeSolo
          onChange={(e, v) => setProduct(v)}
          options={products.map(option => option.title)}
          renderInput={params => (
            <CssTextField {...params}
              label="Pesquisar personalizado"
              margin="normal"
              variant="outlined"
              className={classes.margin}
            />
          )}
        />
      </div>
    </div>
  )
}

export default AutoComplete